import ButtonGroupItemModel from '../../../components/buttonGroup/logic/model/buttonGroupItemModel'
import ActionType from '../../../enums/actionType'

export default class CandidateButtonGroupItemModelFactory {

    public createType(type: ActionType): ButtonGroupItemModel {
        switch(type) {
            case ActionType.Approve: return this.asApprove()
            case ActionType.MoveToWaitingList: return this.asWaitingList()
            case ActionType.Reject: return this.asReject()
            case ActionType.RejectWithoutMessage: return this.asRejectWithoutMessage()
            case ActionType.SendMessage: return this.asSendMessage()
            case ActionType.RequestExtraInfo: return this.asExtraInfo()
            case ActionType.Invite: return this.asInvite()
            case ActionType.Screen: return this.asScreen()
            case ActionType.AssignTenant: return this.asAssignTenant()
            default: throw Error(`AcionType ${type} is not supported yet!`)
        }
    }

    private asApprove(): ButtonGroupItemModel {
        return new ButtonGroupItemModel(
            'candidate.action.approve',
            ActionType.Approve
        )
    }

    private asWaitingList(): ButtonGroupItemModel {
        return new ButtonGroupItemModel(
            'candidate.action.queue',
            ActionType.MoveToWaitingList
        )
    }

    private asReject(): ButtonGroupItemModel {
        return new ButtonGroupItemModel(
            'candidate.action.reject',
            ActionType.Reject
        )
    }

    private asRejectWithoutMessage(): ButtonGroupItemModel {
        return new ButtonGroupItemModel(
            'candidate.action.reject.withoutMessage',
            ActionType.RejectWithoutMessage
        )
    }

    private asSendMessage(): ButtonGroupItemModel {
        return new ButtonGroupItemModel(
            'candidate.action.initiateMessage',
            ActionType.SendMessage
        )
    }

    private asExtraInfo(): ButtonGroupItemModel {
        return new ButtonGroupItemModel(
            'candidate.action.extraInfo',
            ActionType.RequestExtraInfo
        )
    }

    private asInvite(): ButtonGroupItemModel {
        return new ButtonGroupItemModel(
            'candidate.action.invite',
            ActionType.Invite
        )
    }

    private asScreen(): ButtonGroupItemModel {
        return new ButtonGroupItemModel(
            'candidate.action.screen',
            ActionType.Screen
        )
    }

    private asAssignTenant(): ButtonGroupItemModel {
        return new ButtonGroupItemModel(
            'candidate.action.assignTenant',
            ActionType.AssignTenant
        )
    }
}
