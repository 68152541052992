import React, {useState} from 'react'
import Modal from '../organisms/modal/Modal'
import Button from '../buttons/button/Button'
import ButtonType from '../buttons/button/logic/models/buttonType'
import ColorEnum from '../../enums/colorEnum'
import Title from '../typography/title/Title'
import FontSizeEnum from '../typography/enums/fontSizeEnum'
import FontWeightEnum from '../typography/enums/fontWeightEnum'

interface CreateNoteModalProps {
    readonly isOpen: boolean
    readonly onSubmitButtonClick: (note: string) => void
    readonly onCloseButtonClick: () => void
}

function CreateNoteModal(props: CreateNoteModalProps) {

    const [note, setNote] = useState('')

    return (
        <Modal
            flowyColor={ColorEnum.Primary100}
            isOpen={props.isOpen}
            onCloseButtonClick={() => props.onCloseButtonClick()}
        >
            <div>
                <Title
                    size={FontSizeEnum.Xxsm}
                    weight={FontWeightEnum.Medium}
                    color={ColorEnum.Primary700}
                >
                    Notitie opstellen
                </Title>
                <textarea
                    rows={5}
                    defaultValue={note}
                    onChange={(e) => setNote(e.target.value)}
                    name="comment"
                    id="comment"
                    className="md:w-[600px] shadow-sm block w-full sm:text-md border-gray-300 rounded-md my-4"
                    placeholder="Schrijf een bericht..."
                />
                <div className="flex justify-end">
                    <Button
                        title={'Notitie opslaan'}
                        icon={'ri-add-line'}
                        buttonType={ButtonType.Primary}
                        onClick={() => props.onSubmitButtonClick(note)}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default CreateNoteModal
