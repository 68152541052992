import Modal from '../../organisms/modal/Modal';
import ColorEnum from '../../../enums/colorEnum';
import Text from '../../typography/text/Text';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import FontWeightEnum from '../../typography/enums/fontWeightEnum';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import TextInput from '../../forms/textInput/TextInput';
import ButtonType from '../../buttons/button/logic/models/buttonType';
import ButtonSize from '../../buttons/button/logic/models/buttonSize';
import CopyButton from '../../buttons/button/CopyButton';
import {useParams} from 'react-router-dom';
import styles from './GenerateContactRequestLinkModal.module.css';
import Button from '../../buttons/button/Button';

interface GenerateContactRequestLinkModalProps {
    readonly isOpen: boolean,
    readonly setIsGenerateContactRequestLinkModalOpen: (boolean: boolean) => void,
}
function GenerateContactRequestLinkModal(props: GenerateContactRequestLinkModalProps) {

    const {t} = useTranslation()

    function getURLForContactRequestPage() {
        const id: string = useParams().id
        const baseUrl = window.location.origin
        return `${baseUrl}/contact-verzoek/${id}`
    }

    return (
        <Modal
            flowyColor={ColorEnum.Primary100}
            isOpen={props.isOpen}
            isNonScrollable={true}
            onCloseButtonClick={() => props.setIsGenerateContactRequestLinkModalOpen(false)}
            canOverflow={true}
        >
            <div className='min-w-full md:w-[705px]'>
                <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-5">
                        <Text
                            size={FontSizeEnum.Lg}
                            weight={FontWeightEnum.Bold}
                            color={ColorEnum.Gray800}
                        >
                            {t('listing.details.generateContactRequestLinkModal.title')}
                        </Text>
                    </div>
                </div>
                <div>
                    <div className={styles.description}>
                        <Text
                            size={FontSizeEnum.Md}
                            color={ColorEnum.Gray800}
                        >
                            <Trans i18nKey={'listing.details.generateContactRequestLinkModal.description'} components={{
                                p: <p style={{marginBottom: '0.5rem'}}/>,
                                ul: <ul style={{textAlign: 'left', listStyle: 'initial', marginLeft: '1rem'}}/>,
                                li: <li style={{marginBottom: 8}}/>
                            }}/>
                        </Text>
                    </div>
                    <div className='h-full'
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '8px'}}>
                        <div style={{flex: 1}}>
                            <TextInput name={'test'} value={getURLForContactRequestPage()} isDisabled={true}
                                keepColorOnDisabled={true}></TextInput>
                        </div>
                        <div>
                            <CopyButton textToCopy={getURLForContactRequestPage()} tooltipText={t('listing.details.generateContactRequestLinkModal.copyButton.tooltip')}
                                buttonType={ButtonType.SecondaryGray} size={ButtonSize.Lg}
                                icon={'ri-file-copy-line'}></CopyButton>
                        </div>
                    </div>
                    <div className={`${styles.closeButton} flex justify-end`}>
                        <Button
                            title={t('listing.details.generateContactRequestLinkModal.closeButton')}
                            buttonType={ButtonType.Primary}
                            onClick={() => props.setIsGenerateContactRequestLinkModalOpen(false)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default GenerateContactRequestLinkModal;
