const DEFAULT_INVITATION_STATUS = 'not_invited';

const initialState = {
    data: {
        selectedCandidate: {
            availableSince: null,
            birthday: null,
            email: '',
            employment: '',
            firstName: '',
            formLastSentOn: null,
            extraInfoFormLastSentOn: null,
            gender: '',
            guarantor: '',
            household: '',
            householdType: 'none',
            id: '',
            income: 0,
            lastName: '',
            listing: {
                agent: {},
                details: {
                    city: '',
                    house_number: '',
                    link: '',
                    origin_name: '',
                    street: '',
                    id: ''
                },
                city: '',
                house_number: '',
                link: '',
                origin_name: '',
                street: '',
                id: ''
            },
            locale: '',
            messages: [{
                createdAt: '',
                details: {
                    body: '',
                    subject: ''
                }
            }],
            notes: [],
            pets: null,
            phone: '',
            status: null,
            viewingInvitationStatus: DEFAULT_INVITATION_STATUS,
            invitedForViewingAt: null,
            term: null,
            hasAcceptedAgentPrivacyPolicy: false,
            screeningRequest: null,
            isListingTenant: false
        },
        enrichingCandidate: {
            agent: {},
            listing: {},
            candidate: {}
        }
    },
    error: null,
    isLoading: false,
    errorMessage: '',
    code: 200
};

export default function (state = initialState, action = {}) {
    const { payload, type } = action;
    switch (type) {
        case 'SET_ENRICHING_CANDIDATE_DETAILS': return {
            ...state,
            data: {
                ...state.data,
                enrichingCandidate: {
                    ...state.data.enrichingCandidate,
                    candidate: payload
                }
            }
        };
        case 'SET_ENRICHING_CANDIDATE_TRANSACTIONAL_LISTING': return {
            ...state,
            data: {
                ...state.data,
                enrichingCandidate: {
                    ...state.data.enrichingCandidate,
                    agent: payload.agent,
                    listing: {
                        id: payload.id,
                        link: payload.link,
                        origin_name: payload.originName,
                        street: payload.street,
                        show_house_number: payload.showHouseNumber,
                        house_number: payload.houseNumber,
                        image_url: payload.imageUrl,
                        postal_code: payload.postalCode,
                        city: payload.city,
                        price: payload.price,
                        surface: payload.surface,
                        rooms: payload.rooms,
                    },
                    status: payload.status
                }
            }
        };
        case 'SET_CANDIDATE_DETAILS': return {
            ...state,
            data: {
                ...state.data,
                selectedCandidate: {
                    availableSince: payload.availableSince,
                    birthday: payload.birthday,
                    email: payload.email,
                    employment: payload.employment,
                    firstName: payload.firstName,
                    formLastSentOn: payload.formLastSentOn,
                    extraInfoFormLastSentOn: payload.extraInfoFormLastSentOn,
                    gender: payload.gender,
                    guarantor: payload.guarantor,
                    household: payload.household,
                    householdType: payload.householdType,
                    id: payload.id,
                    income: payload.income,
                    lastName: payload.lastName,
                    transactionalListing: {
                        agent: payload.listing.agent,
                        city: payload.listing.city,
                        house_number: payload.listing.house_number,
                        link: payload.listing.link,
                        origin_name: payload.listing.origin_name,
                        street: payload.listing.street,
                        id: payload.listing.id
                    },
                    locale: payload.locale,
                    messages: payload.messages,
                    notes: payload.notes,
                    pets: payload.pets,
                    phone: payload.phone,
                    status: payload.status,
                    viewingInvitationStatus: payload.viewingInvitationStatus,
                    invitedForViewingAt: payload.invitedForViewingAt,
                    term: payload.term,
                    hasAcceptedAgentPrivacyPolicy: payload.hasAcceptedAgentPrivacyPolicy,
                    screeningRequest: payload.screeningRequest,
                    isListingTenant: payload.isListingTenant
                }
            }
        };
        default:
            return state;
    }
}
