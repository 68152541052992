import ButtonGroupMenuModel from '../../../components/buttonGroup/logic/model/buttonGroupMenuModel'
import ButtonGroupModel from '../../../components/buttonGroup/logic/model/buttonGroupModel'
import ActionType from '../../../enums/actionType'
import CandidatePlace from '../../../enums/candidatePlace'
import CandidateModel from '../candidateModel'
import CandidateButtonGroupItemModelFactory from './candidateButtonGroupItemModelFactory'
import ButtonGroupItemModel from '../../../components/buttonGroup/logic/model/buttonGroupItemModel'

export default class CandidateButtonGroupModelFactory {
    constructor(
        private readonly selectedListingHasTenant = false,
        private readonly doesShowRejectWithoutMessage = true,
        private readonly isProperizeEnabled = false,
        private readonly factory = new CandidateButtonGroupItemModelFactory()
    ) { }

    public createFor(candidate: CandidateModel): ButtonGroupModel {
        switch (candidate.getPlace()) {
            case CandidatePlace.New: return this.forNew()
            case CandidatePlace.BeingAssessed: return this.forAssessment(candidate)

            case CandidatePlace.Approved:
            case CandidatePlace.IsListingTenant: return this.forApproved(candidate)

            case CandidatePlace.ReceivedExtraInfoForm:
            case CandidatePlace.FilledInExtraInfoForm: return this.forExtraInfoForm()

            case CandidatePlace.InvitedToViewing:
            case CandidatePlace.AcceptedInvitationToViewing:
            case CandidatePlace.DeclinedInvitationToViewing: return this.forInvitedToViewing(candidate)

            case CandidatePlace.OnWaitingList: return this.forWaitingList(candidate)
            case CandidatePlace.Rejected: return this.forRejected(candidate)
        }
    }

    private forNew(): ButtonGroupModel {
        const items = [this.factory.createType(ActionType.RequestExtraInfo)]

        const menuItems = [
            this.factory.createType(ActionType.Approve),
            this.factory.createType(ActionType.MoveToWaitingList),
            this.factory.createType(ActionType.Reject),
            this.factory.createType(ActionType.RejectWithoutMessage).withIsHidden(!this.doesShowRejectWithoutMessage),
            this.factory.createType(ActionType.SendMessage)
        ]
        return ButtonGroupModel.create(items, ButtonGroupMenuModel.create(menuItems))
    }

    private forAssessment(candidate: CandidateModel): ButtonGroupModel {
        const items = [
            this.factory.createType(ActionType.Approve),
            this.factory.createType(ActionType.Reject)
        ]

        const menuItems = [
            this.factory.createType(ActionType.MoveToWaitingList),
            this.factory.createType(ActionType.RequestExtraInfo).withIsHidden(candidate.hasReceivedExtraInfoForm()),
            this.factory.createType(ActionType.RejectWithoutMessage).withIsHidden(!this.doesShowRejectWithoutMessage),
            this.factory.createType(ActionType.SendMessage),
        ]
        return ButtonGroupModel.create(items, ButtonGroupMenuModel.create(menuItems))
    }

    private forApproved(candidate: CandidateModel): ButtonGroupModel {
        const items = [this.createMainButtonForApproved(candidate)]

        const menuItems = [
            this.factory.createType(ActionType.Screen).withIsHidden(!this.isProperizeEnabled || candidate.isInvitedForScreening() || candidate.hasDoneScreening()),
            this.factory.createType(ActionType.AssignTenant).withIsHidden(!this.isProperizeEnabled || candidate.hasDoneScreening() || this.selectedListingHasTenant),
            this.factory.createType(ActionType.MoveToWaitingList),
            this.factory.createType(ActionType.Reject),
            this.factory.createType(ActionType.RejectWithoutMessage).withIsHidden(!this.doesShowRejectWithoutMessage),
            this.factory.createType(ActionType.SendMessage).withIsHidden(candidate.isInvitedToViewingPending() || this.selectedListingHasTenant),
            this.factory.createType(ActionType.RequestExtraInfo).withIsHidden(candidate.hasReceivedExtraInfoForm()),
        ]

        return ButtonGroupModel.create(items, ButtonGroupMenuModel.create(menuItems))
    }

    private createMainButtonForApproved(candidate: CandidateModel): ButtonGroupItemModel {
        if (!this.selectedListingHasTenant) {
            if (this.isProperizeEnabled && candidate.hasDoneScreening()) {
                return this.factory.createType(ActionType.AssignTenant)
            }

            if (!candidate.isInvitedToViewingPending()) {
                return this.factory.createType(ActionType.Invite)
            }
        }

        return this.factory.createType(ActionType.SendMessage)
    }

    private forWaitingList(candidate: CandidateModel): ButtonGroupModel {
        const items = [
            this.factory.createType(ActionType.Approve),
            this.factory.createType(ActionType.Reject)
        ]

        const menuItems = [
            this.factory.createType(ActionType.RequestExtraInfo).withIsHidden(candidate.hasReceivedExtraInfoForm()),
            this.factory.createType(ActionType.RejectWithoutMessage).withIsHidden(!this.doesShowRejectWithoutMessage),
            this.factory.createType(ActionType.SendMessage),
        ]
        return ButtonGroupModel.create(items, ButtonGroupMenuModel.create(menuItems))
    }

    private forRejected(candidate: CandidateModel): ButtonGroupModel {
        const items = [this.factory.createType(ActionType.SendMessage)]

        const menuItems = [
            this.factory.createType(ActionType.Approve),
            this.factory.createType(ActionType.MoveToWaitingList),
            this.factory.createType(ActionType.RequestExtraInfo).withIsHidden(candidate.hasReceivedExtraInfoForm()),
        ]
        return ButtonGroupModel.create(items, ButtonGroupMenuModel.create(menuItems))
    }

    private forExtraInfoForm(): ButtonGroupModel {
        const items = [
            this.factory.createType(ActionType.Approve),
            this.factory.createType(ActionType.Reject)
        ]

        const menuItems = [
            this.factory.createType(ActionType.MoveToWaitingList),
            this.factory.createType(ActionType.RejectWithoutMessage).withIsHidden(!this.doesShowRejectWithoutMessage),
            this.factory.createType(ActionType.SendMessage),
        ]

        return ButtonGroupModel.create(items, ButtonGroupMenuModel.create(menuItems))
    }

    private forInvitedToViewing(candidate: CandidateModel): ButtonGroupModel {
        const items = [this.createMainButtonForInvitedToViewing(candidate)]

        const menuItems = [
            this.factory.createType(ActionType.Screen).withIsHidden(!this.isProperizeEnabled || (this.isProperizeEnabled && ((candidate.hasDoneScreening() || candidate.isInvitedForScreening()) || (!candidate.isInvitedForScreening() && !candidate.hasDoneScreening())))),
            this.factory.createType(ActionType.AssignTenant).withIsHidden(!this.isProperizeEnabled || candidate.hasDoneScreening()),
            this.factory.createType(ActionType.MoveToWaitingList),
            this.factory.createType(ActionType.Invite),
            this.factory.createType(ActionType.Reject),
            this.factory.createType(ActionType.RejectWithoutMessage).withIsHidden(!this.doesShowRejectWithoutMessage),
            this.factory.createType(ActionType.SendMessage).withIsHidden(!this.isProperizeEnabled || (this.isProperizeEnabled && (!candidate.hasDoneScreening() && candidate.isInvitedForScreening()))),
        ]

        return ButtonGroupModel.create(items, ButtonGroupMenuModel.create(menuItems))
    }

    private createMainButtonForInvitedToViewing(candidate: CandidateModel): ButtonGroupItemModel {
        if (this.isProperizeEnabled) {
            if (candidate.hasDoneScreening()) {
                return this.factory.createType(ActionType.AssignTenant);
            }

            if (!candidate.isInvitedForScreening()) {
                return this.factory.createType(ActionType.Screen);
            }
        }

        return this.factory.createType(ActionType.SendMessage)
    }
}
