import React from 'react';
import PropTypes from 'prop-types';

function ButtonLink({children, className, onClick}) {
    return (
        <button
            onClick={() => onClick()}
            className={`appearance-none font-semibold text-primary underline ${className}`}>
            {children}
        </button>
    );
}


ButtonLink.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default ButtonLink;
