import styles from './TextArea.module.css'
import ReactQuill, { Quill } from 'react-quill'
import React, {useEffect, useRef, useState} from 'react'
import 'react-quill/dist/quill.snow.css';
import ImageCompress from 'quill-image-compress';

Quill.register('modules/imageCompress', ImageCompress);

const H1 = 1;
const H2 = 2;
const H3 = 3;
const H4 = 4;
const p = false; // this value is false to enable the <p> tag. (Basically saying headers = false)

export interface TextAreaProps {
    readonly allowImage?: boolean
    readonly value: string
    readonly onChange: (val: string) => void
}

export default function TextArea(props: TextAreaProps) {
    const quillRef = useRef(null);
    const [editorContent, setEditorContent] = useState<string>('')

    let image = '';
    if (props.allowImage == true) {
        image = 'image';
    }

    const modules = {
        toolbar: [
            [{ 'header': [H1, H2, H3, H4, p] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', image]
        ],
        imageCompress: {
            maxWidth: 140,
            maxHeight: 140,
            imageType: ['image/jpeg', 'image/png']
        }
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', image
    ]

    useEffect(() => {
        if (props.value !== editorContent) {
            setEditorContent(props.value)
        }
    }, [props.value])

    const handleChange = (content: string) => {
        setEditorContent(content)
        props.onChange(content)
    }

    return (
        <div className={styles.root} id="textArea">
            <ReactQuill
                className={styles.textArea}
                ref={quillRef}
                theme="snow"
                modules={modules}
                formats={formats}
                value={editorContent}
                onChange={(val) => handleChange(val)}
            />
        </div>
    )
}
