import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment, FunctionComponent } from 'react'
import ActionType from '../../../enums/actionType'
import MenuAlignment from '../enums/menuAlignment'
import ButtonGroupMenuModel from '../logic/model/buttonGroupMenuModel'
import ButtonGroupMenuItem from './ButtonGroupMenuItem'

export interface ButtonGroupMenuProps {
    readonly model: ButtonGroupMenuModel
    readonly dropDownAlignment?: MenuAlignment
    readonly onClick: (actionType: ActionType) => void
}

const ButtonGroupMenu: FunctionComponent<ButtonGroupMenuProps> = (props: ButtonGroupMenuProps) => {
    return (
        <Menu as="div" className="relative h-full -ml-px block">
            <Menu.Button
                data-testid="menu-button"
                as='button'
                disabled={props.model.isDisabled}
                className={`relative h-full inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2
                        py-2 text-sm font-medium text-gray-500 hover:bg-gray-50
                        focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300
                        ${props.model.isDisabled ? 'bg-transparent text-gray-200 hover:bg-gray-50 cursor-not-allowed' : 'hover:bg-gray-50'}
                    `}>
                <span className="sr-only">Open options</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <div
                    data-testid="menu-items-container"
                    className={`absolute right-0 z-50 mt-2 mr-1 w-56 origin-top-right rounded-md bg-white
                        shadow-lg ring-1 ring-black ring-opacity-5 focus-visible:outline-none 
                        ${props.dropDownAlignment === MenuAlignment.Above ? 'bottom-full mb-2' : ''}
                    `}
                >
                    <Menu.Items as='div' className="focus-visible:outline-secondary">
                        <div data-testid="menu-items" className="py-1">
                            {props.model.items.map((item, i) => !item.isHidden && (
                                <div key={i} data-testid={'menu-item-' + i}>
                                    <ButtonGroupMenuItem
                                        model={item}
                                        onClick={(a: ActionType) => props.onClick(a)}
                                    />
                                </div>
                            ))}
                        </div>
                    </Menu.Items>
                </div>
            </Transition>
        </Menu>
    )
}

ButtonGroupMenu.defaultProps = {
    dropDownAlignment: MenuAlignment.Below
}

export default ButtonGroupMenu
