import {request} from '../../../utils/api';
import {getCandidateDetails} from './getCandidateDetails';

export const changeCandidateViewingInvitationStatus = (token, ids, transition, subject, body) => async dispatch => {
    dispatch({type: 'START_LOADING'});
    await request(token).post(`/api/bulk/candidates/viewing/${transition}`, {
        candidatesIds: [...ids],
        messageTitle: subject,
        messageBody: body,
    }).then(response => {
        dispatch({
            type: 'CHANGE_CANDIDATE_VIEWING_INVITATION_STATUS',
            payload: response.data
        });
    })
        .then(() => dispatch(getCandidateDetails(token, ids[0])))
        .catch((e) => {
            dispatch({type: 'STOP_LOADING'});
            throw e;
        })
        .finally(() => dispatch({type: 'STOP_LOADING'}));
};
