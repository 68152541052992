import React, {ReactNode} from 'react'
import styles from './PageContainer.module.css'

interface props {
    readonly children: ReactNode
}

const PageContainer = ({children}: props) => {
    return (
        <div className={styles.outer}>
            <div className={styles.inner}>
                {children}
            </div>
        </div>
    )
}

export default PageContainer
