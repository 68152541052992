import {Link} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import AgentDetailsFormServiceInterface from './logic/service/agentDetailsFormServiceInterface'
import TextInput from '../textInput/TextInput'
import FormError from '../../banners/FormError'
import MultipleEntrySelector from '../../multiple-selection/MultipleEntrySelector'
import AgentInfo from './components/agentInfo/AgentInfo'
import Button from '../../buttons/button/Button'
import ButtonType from '../../buttons/button/logic/models/buttonType'
import {useTranslation} from 'react-i18next'
import ButtonActionType from '../../buttons/button/logic/models/buttonActionType'
import Checkbox from '../checkbox/Checkbox'
import styles from './AgentDetailsForm.module.css'
import RadioButton from '../radiobutton/RadioButton'

const UUID_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

const formValidationSchema = Yup.object().shape({
    logoUrl: Yup.string().url('Deze URL is ongeldig').required('Verplicht veld'),
    websiteUrl: Yup.string().url('Deze URL is ongeldig').required('Verplicht veld'),
    defaultEmail: Yup.string().email('Deze Email adres is ongeldig').required('Verplicht veld'),
    name: Yup.string().required('Verplicht veld'),
    enrichingTemplateId: Yup.string().required('Verplicht veld'),
    defaultTemplateId: Yup.string().required('Verplicht veld'),
    rejectionTemplateId: Yup.string().required('Verplicht veld'),
    privacyUrl: Yup.string().url('Deze URL is ongeldig').required('Verplicht veld'),

    parariusOfficeApiKey: Yup.string().trim(),
    defaultPhone: Yup.number().typeError('Voer een geldig telefoonnummer in'),
    street: Yup.string(),
    city: Yup.string(),
    show_house_number: Yup.boolean(),
    isEmailSecure: Yup.boolean(),
    houseNumber: Yup.string(),
    postalCode: Yup.string(),
    commercialContactType: Yup.string(),
    zohoId: Yup.string().matches(/^[0-9]+$/, 'Mag alleen cijfers zijn').min(18, 'Moet precies 18 cijfers bevatten').max(18, 'Moet precies 18 cijfers bevatten').required('Verplicht veld')
})

function validateEmail(entry) {
    try {
        Yup.object()
            .shape({
                email: Yup.string().email('Geen emails adres'),
            })
            .validateSync({
                email: entry,
            })
    } catch (err) {
        return {success: false, message: err.message}
    }
    return {success: true, message: null}
}

function validateUuid(entry) {
    try {
        Yup.object()
            .shape({
                uuid: Yup.string().trim().matches(UUID_REGEX, 'Deze UUID is ongeldig'),
            })
            .validateSync({
                uuid: entry,
            })
    } catch (err) {
        return {success: false, message: err.message}
    }
    return {success: true, message: null}
}

export interface AgentDetailsFormProps {
    readonly agentDetailsFormService: AgentDetailsFormServiceInterface
    readonly agentData: any
    readonly onSubmitHandler: (val: any) => void
}

function AgentDetailsForm(props: AgentDetailsFormProps) {
    const {agentData, onSubmitHandler} = props
    const [leadsApiUuids, setLeadsApiUuids] = useState([])
    const [inquiriesAddresses, setInquiriesAddresses] = useState([])
    const [adminAddresses, setAdminAddresses] = useState([])
    const [leadApiAgentId, setLeadApiAgentId] = useState<string>()

    useEffect(() => {
        if (!agentData)
            return
        setLeadsApiUuids(agentData.leadsAgentIds)
        setAdminAddresses(agentData.loginsPopulated)
        setInquiriesAddresses(agentData.emailsForInquiries)
    }, [agentData])

    const {t} = useTranslation()
    const formik = useFormik({
        initialValues: {
            name: agentData?.name || '',
            defaultTemplateId: agentData?.emailTemplateId || '',
            rejectionTemplateId: agentData?.rejectionTemplateId || '',
            defaultPhone: agentData?.defaultPhone || '',
            street: agentData?.street || '',
            houseNumber: agentData?.houseNumber || '',
            parariusOfficeApiKey: agentData?.parariusOfficeKey || '',
            privacyUrl: agentData?.privacyPolicy || '',
            city: agentData?.city || '',
            logoUrl: agentData?.logo || '',
            websiteUrl: agentData?.url || '',
            postalCode: agentData?.postalCode || '',
            defaultEmail: agentData?.defaultEmail || '',
            enrichingTemplateId: agentData?.enrichmentTemplateId || '',
            showHouseNumber: agentData?.showHouseNumber || false,
            isEmailSecure: agentData?.isEmailSecure || false,
            commercialContactType: agentData?.commercialContactType || 'NONE',
            zohoId: agentData?.zohoId || ''
        },
        enableReinitialize: true,
        validationSchema: formValidationSchema,
        onSubmit: () => {
            return onSubmitHandler({
                city: formik.values.city,
                street: formik.values.street,
                houseNumber: formik.values.houseNumber,
                logo: formik.values.logoUrl,
                defaultEmail: formik.values.defaultEmail,
                defaultPhone: formik.values.defaultPhone,
                inquiriesAddresses,
                leadsApiUuids,
                rejectionTemplateId: formik.values.rejectionTemplateId,
                enrichmentTemplateId: formik.values.enrichingTemplateId,
                postalCode: formik.values.postalCode,
                name: formik.values.name,
                privacyPolicy: formik.values.privacyUrl,
                url: formik.values.websiteUrl,
                emailTemplateId: formik.values.defaultTemplateId,
                showHouseNumber: formik.values.showHouseNumber,
                isEmailSecure: formik.values.isEmailSecure,
                parariusOfficeKey: formik.values.parariusOfficeApiKey,
                loginsPopulated: adminAddresses || [],
                commercialContactType: formik.values.commercialContactType,
                zohoId: formik.values.zohoId
            })
        },
    })

    return (
        <>
            <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
                <div
                    className="mt-1 block p-5 w-full shadow-sm sm:text-sm
                  border-gray-300 bg-gray-100 rounded-md">
                    <h2 className="text-xl font-bold">Gegevens Kantoor</h2>

                    <p className="pb-3 italic">
                        De kantoor-instellingen voor {agentData && agentData.name || 'de makelaar'}.
                    </p>

                    <div className="mb-3">
                        <TextInput
                            label={'Naam Kantoor'}
                            isMandatory
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.name}/>
                    </div>

                    <div className="mb-3 md:grid grid-cols-8 gap-2">
                        <div className="col-span-6">
                            <TextInput
                                label={'Straat Kantoor'}
                                name="street"
                                value={formik.values.street}
                                onChange={formik.handleChange}
                            />
                        </div>

                        <div className="col-span-2">
                            <TextInput
                                label={'Huisnummer Kantoor'}
                                name="houseNumber"
                                value={formik.values.houseNumber}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="mb-3 md:grid grid-cols-8 gap-2">
                        <div className="col-span-6">
                            <TextInput
                                label={'Vestigingsplaats Kantoor'}
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                            />
                        </div>

                        <div className="col-span-2">
                            <TextInput
                                label={'Postcode Kantoor'}
                                name="postalCode"
                                value={formik.values.postalCode}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>

                    <div className="mb-3">
                        <TextInput
                            label={'Telefoonnummer'}
                            name="defaultPhone"
                            value={formik.values.defaultPhone}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.defaultPhone}/>
                    </div>

                    <div className="mb-3">
                        <TextInput
                            label={'Website URL'}
                            name="websiteUrl"
                            value={formik.values.websiteUrl}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.websiteUrl}/>
                    </div>

                    <div className="mb-3">
                        <TextInput
                            label={'Privacy beleid URL'}
                            isMandatory
                            name="privacyUrl"
                            value={formik.values.privacyUrl}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.privacyUrl}/>
                    </div>

                    <div className="mb-3">
                        <TextInput
                            label={'Logo URL'}
                            isMandatory
                            name="logoUrl"
                            value={formik.values.logoUrl}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.logoUrl}/>
                    </div>

                    <div className="mb-3">
                        <TextInput
                            label={'ZoHo ID'}
                            isMandatory
                            name="zohoId"
                            value={formik.values.zohoId}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.zohoId}/>
                    </div>
                </div>

                <div
                    className="mt-6 block p-5  w-full shadow-sm sm:text-sm
                  border-gray-300 bg-gray-100 rounded-md">
                    <h2 className="text-xl font-bold">Instellingen Annotatie</h2>

                    <p className="pb-3 italic">
                        De annotatie-Instellingen voor {agentData && agentData.name || 'de makelaar'}.
                    </p>

                    <div className="mb-3">
                        <p className="my-2">
                            Leads API UUID&aposs <i>(Pararius en Huurwoningen)</i>
                        </p>

                        <MultipleEntrySelector
                            list={leadsApiUuids}
                            textBoxPlaceholder="00000000-0000-0000-0000-000000000000"
                            validationFn={validateUuid}
                            onChange={(val) => setLeadsApiUuids(val)}
                            onValidationPass={(val) => setLeadApiAgentId(val)}
                            onValidationFail={() => setLeadApiAgentId(undefined)}
                        />


                        {leadApiAgentId &&
                            <AgentInfo agentId={leadApiAgentId} service={props.agentDetailsFormService}/>}

                    </div>

                    <div className="mb-3">
                        <TextInput
                            label={'Pararius Office API key'}
                            name="parariusOfficeApiKey"
                            value={formik.values.parariusOfficeApiKey}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.parariusOfficeApiKey}/>
                    </div>
                    <div className="mb-3">
                        <p className="my-2">E-mail waarop aanvragen binnenkomen</p>
                        <MultipleEntrySelector
                            list={inquiriesAddresses}
                            onChange={(data) => {
                                setInquiriesAddresses(data)
                            }}
                            validationFn={validateEmail}
                            textBoxPlaceholder="mp@treehouse.com"
                        />
                    </div>
                </div>

                <div className="office-data mt-6 block p-5  w-full shadow-sm sm:text-sm border-gray-300 bg-gray-100 rounded-md">
                    <h2 className="text-xl font-bold">Email Verzenden</h2>
                    <p className="pb-3 italic">
                        De email-instellingen voor {agentData && agentData.name || 'de makelaar'}.
                    </p>

                    <div className="mb-3">
                        <div className={styles.checkbox2}>
                            <Checkbox
                                id="isEmailSecure"
                                isChecked={formik.values.isEmailSecure}
                                onChange={formik.handleChange}
                            />
                            <label htmlFor="isEmailSecure">
                                E-mail verzenden namens makelaar
                                <br/>
                            </label>
                        </div>
                        <p className=" mb-3 italic text-gray-500">Verstuurt standaard via @leadflow.rent</p>
                    </div>

                    <div className="mb-3">

                        <TextInput
                            label={'Verzendadres E-mail'}
                            isMandatory
                            name="defaultEmail"
                            value={formik.values.defaultEmail}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.defaultEmail}/>
                    </div>

                    <div className="mb-3">
                        <TextInput
                            label={'Handtekening e-mail template ID'}
                            isMandatory
                            name="defaultTemplateId"
                            value={formik.values.defaultTemplateId}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.defaultTemplateId}/>
                    </div>

                    <div className="mb-3">
                        <TextInput
                            label={'Verrijking e-mail template ID'}
                            isMandatory
                            name="enrichingTemplateId"
                            value={formik.values.enrichingTemplateId}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.enrichingTemplateId}/>
                    </div>

                    <div className="mb-3">
                        <TextInput
                            label={'Afwijzing e-mail template ID'}
                            isMandatory
                            name="rejectionTemplateId"
                            value={formik.values.rejectionTemplateId}
                            onChange={formik.handleChange}
                        />
                        <FormError message={formik.errors.rejectionTemplateId}/>
                    </div>
                </div>

                <div className="office-data mt-6 block p-5 w-full shadow-sm sm:text-sm border-gray-300 bg-gray-100 rounded-md">
                    <h2 className="text-xl font-bold">Algemene Instellingen</h2>
                    <p className="pb-3 italic">De instellingen voor {agentData && agentData.name || 'de makelaar'}.</p>

                    <div className="flex flex-col w-full gap-2">
                        <Checkbox
                            id="showHouseNumber"
                            label={t('agent.form.generalSettings.showHouseNumber')}
                            isChecked={formik.values.showHouseNumber}
                            onChange={(val) => formik.handleChange(val)}
                        />
                        <div className="sm:col-span-2 col-span-2">
                            <div className="space-y-1 my-2">
                                <label
                                    htmlFor="commercial-contact-type"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('agent.form.generalSettings.addCommercialContact')}
                                </label>
                                <div className="flex items-center">
                                    <RadioButton
                                        onChange={(e) => {formik.handleChange(e)}}
                                        id="commercial-contact-type-allow-none"
                                        name="commercialContactType"
                                        value="NONE"
                                        isChecked={formik.values.commercialContactType === 'NONE'}
                                    />
                                    <label
                                        htmlFor="commercial-contact-type-allow-none"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                        Geen commercieel contact toestaan
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <RadioButton
                                        onChange={(e) => {formik.handleChange(e)}}
                                        id="exclude-agent-website-leads"
                                        name="commercialContactType"
                                        value="EXCLUDE_AGENT_WEBSITE_LEADS"
                                        isChecked={formik.values.commercialContactType === 'EXCLUDE_AGENT_WEBSITE_LEADS'}
                                    />
                                    <label
                                        htmlFor="exclude-agent-website-leads"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                        Website leads van makelaar uitsluiten van commercieel contact
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <RadioButton
                                        onChange={(e) => {formik.handleChange(e)}}
                                        id="commercial-contact-type-allow-all"
                                        name="commercialContactType"
                                        value="TO_ALL"
                                        isChecked={formik.values.commercialContactType === 'TO_ALL'}
                                    />
                                    <label
                                        htmlFor="commercial-contact-type-allow-all"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                        Alle leads benaderen met commercieel contact
                                    </label>
                                </div>
                            </div>
                        </div>

                        <Checkbox
                            label={t('agent.form.generalSettings.screening')}
                            isChecked={agentData.hasActivatedScreeningService}
                            isDisabled
                        />
                    </div>
                </div>
                <div
                    className="office-data mt-6 block p-5  w-full shadow-sm sm:text-sm
                  border-gray-300 bg-gray-100 rounded-md">
                    <h2 className="text-xl font-bold">Leadflow Accounts</h2>
                    <p className="pb-3 italic">
                        Deze accounts hebben toegang tot leadflow namens {agentData && agentData.name || 'de makelaar'}.
                    </p>
                    <div className="mb-3">
                        <p className="my-2">Beheerders Toevoegen</p>
                        <MultipleEntrySelector
                            list={adminAddresses}
                            onChange={(data) => setAdminAddresses(data)}
                            validationFn={validateEmail}
                            textBoxPlaceholder="admin.mp@treehouse.com"
                        />
                    </div>
                </div>
                <div className="mt-4 w-80 flex gap-6 items-center">
                    <Button
                        buttonType={ButtonType.Primary}
                        buttonActionType={ButtonActionType.Submit}
                        title={t('agent.form.save')}
                    />
                    <Link to="/admin" title={t('actions.cancel')} className="text-gray-500">
                        {t('actions.cancel')}
                    </Link>
                </div>
            </form>
        </>
    )
}

export default AgentDetailsForm
