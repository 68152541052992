import HouseholdType from '../../components/candidateData/candidateProfileInfo/logic/models/householdType';
import GuarantorType from '../../components/candidateData/candidateProfileInfo/logic/models/guarantorType';
import {SALUTATION} from '../../utils/constants';
import ImmutableModel from '../../models/immutableModel/immutableModel';

interface ContactRequestFromDataProps{
    salutation: string;
    fullName: string;
    birthDate: string;
    age: number;
    email: string;
    phoneNumber: string;
    employment: string;
    income: number;
    guarantor: GuarantorType;
    householdType: HouseholdType;
    household: number;
    hasPets: boolean;
    rentalStartDate: string;
    rentalPeriod: number;
    motivation: string;
    dataSharingAccepted: boolean;
    privacyPolicyAccepted: boolean;
    errors?: {
        [key: string]: string;
    };
}

export default class ContactRequestFormData extends ImmutableModel<ContactRequestFormData, ContactRequestFromDataProps>{

    public static create(): ContactRequestFormData {

        return new ContactRequestFormData({
            salutation: SALUTATION.UNSET,
            fullName: '',
            email: '',
            phoneNumber: '',
            birthDate: '',
            age: null,
            employment: '',
            income: 0,
            guarantor: GuarantorType.NoGuarantor,
            householdType: null,
            household: null,
            hasPets: null,
            rentalStartDate: '',
            rentalPeriod: 0,
            motivation: '',
            dataSharingAccepted: false,
            privacyPolicyAccepted: false,
        });

    }

    public with(props: Partial<ContactRequestFromDataProps>): ContactRequestFormData {
        const updatedInstance = super.with(props);
        updatedInstance.validate();
        return updatedInstance;
    }

    public hasErrors(): boolean {
        return Object.keys(this.collectErrors()).length > 0;
    }

    private collectErrors(): { [key: string]: string } {
        const errors: { [key: string]: string } = {};

        if (!this.value.salutation) {
            errors.salutation = 'contactRequestPage.form.validation.salutation.empty';
        }
        if (!this.value.fullName) {
            errors.fullName = 'contactRequestPage.form.validation.fullName.empty';
        }
        if (!this.value.email) {
            errors.email = 'contactRequestPage.form.validation.email.empty';
        } else if (!this.validateEmail(this.value.email)) {
            errors.email = 'contactRequestPage.form.validation.email.invalid';
        }
        if (this.value.phoneNumber && !this.validatePhoneNumber(this.value.phoneNumber)) {
            errors.phoneNumber = 'contactRequestPage.form.validation.phoneNumber.invalid';
        }
        if (this.value.income === null || this.value.income < 0) {
            errors.income = 'contactRequestPage.form.validation.income.empty';
        }
        if (!this.value.guarantor) {
            errors.guarantor = 'contactRequestPage.form.validation.guarantor.empty';
        }
        if (this.value.rentalPeriod === null) {
            errors.rentalPeriod = 'contactRequestPage.form.validation.rentalPeriod.empty';
        }
        if (!this.value.dataSharingAccepted || !this.value.privacyPolicyAccepted) {
            errors.terms = 'contactRequestPage.form.validation.terms.empty';
        }

        return errors;
    }

    public validate(): boolean {
        const errors = this.collectErrors();
        if (Object.keys(errors).length > 0) {
            errors.general = 'contactRequestPage.form.validation.general';
        }
        this.value.errors = errors;

        return !this.hasErrors();
    }

    private validateEmail(email: string): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    private validatePhoneNumber(phoneNumber: string): boolean {
        const phoneNumberRegex = /^\+?\d+$/;
        return phoneNumberRegex.test(phoneNumber);
    }

}
