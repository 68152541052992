import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import { removeNotification } from '../../redux/actions/ui/removeNotification';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
function BasicNotification(props) {
    const { id, type, message } = props;
    const dispatch = useDispatch();

    setTimeout(() => {
        dispatch(removeNotification(id));
    }, 5000);

    return (
        <div
            key={message}
            className="w-full flex flex-col items-start justify-center
             space-y-4 my-2">
            <Transition
                show={true}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >

                <div className={classNames('max-w-sm w-full border border-gray-100',
                    'shadow-xl rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden bg-white')}>
                    <div className="p-4">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                {type === 'success' ?
                                    <CheckCircleIcon
                                        className="h-6 w-6 text-green-400"
                                        aria-hidden="true" />
                                    :
                                    <ExclamationCircleIcon
                                        className="h-6 w-6 text-red-400"
                                        aria-hidden="true" />
                                }
                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-md font-medium text-gray-900">
                                    {message}
                                </p>
                            </div>
                            <div className="ml-4 flex-shrink-0 flex">
                                <button
                                    type="button"
                                    className="bg-white rounded-md 
                                    inline-flex text-primary 
                                    hover:bg-gray-100 p-1"
                                    onClick={() => {
                                        dispatch(removeNotification(id));
                                    }}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    );
}

BasicNotification.propTypes = {
    id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

export default BasicNotification;
