import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import FontSizeEnum from '../typography/enums/fontSizeEnum'
import ColorEnum from '../../enums/colorEnum'
import Text from '../typography/text/Text'
import FontWeightEnum from '../typography/enums/fontWeightEnum'
import styles from './ViewingInvitationInvalid.module.css'
import PropTypes from 'prop-types'
import moment from 'moment'

function ViewingInvitationInvalid({listing}) {
    const {t, i18n} = useTranslation()
    const [formattedDateTime, setFormattedDateTime] = useState('')

    useEffect(() => {
        setFormattedDateTime(`${moment(listing.start_date_time).locale(i18n.language).format('dddd D MMMM YYYY, HH:mm')} - ${moment(listing.end_date_time).locale(i18n.language).format('HH:mm')}`)
    }, [i18n.language])

    return (
        <div className={styles.box}>
            <i className={`ri-close-circle-fill ${styles.icon}`}></i>
            <Text
                size={FontSizeEnum.Lg}
                weight={FontWeightEnum.Semibold}
                color={ColorEnum.Primary700}
            >
                {t('viewing.invitation.invalid.title')}
            </Text>
            <div className={styles.divider}/>
            <div className={styles.body}>
                <Text
                    size={FontSizeEnum.Md}
                    color={ColorEnum.Gray800}
                >
                    <Trans i18nKey="viewing.invitation.invalid.body" components={{ bold: <span className='font-bold' /> }} values={{ viewingDateAndTime: formattedDateTime }}></Trans>
                </Text>
            </div>
        </div>
    )
}

ViewingInvitationInvalid.propTypes = {
    listing: PropTypes.object.isRequired
}

export default ViewingInvitationInvalid
