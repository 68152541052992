import React, {useEffect, useState} from 'react'
import styles from './Settings.module.css'
import TopNavigation from '../../components/navigation/TopNavigation'
import PageContainer from '../../componentsNew/atoms/pageContainer/PageContainer'
import Typography from '../../componentsNew/atoms/typography/Typography'
import PageHeader from '../../componentsNew/molecules/pageHeader/PageHeader'
import {useFeature} from '@growthbook/growthbook-react'
import {Navigate} from 'react-router-dom'
import TextArea from '../../components/textArea/TextArea'
import HighlightBlock from '../../componentsNew/molecules/highlightBlock/HighlightBlock'
import {useTranslation} from 'react-i18next'
import Text from '../../components/typography/text/Text'
import FontSizeEnum from '../../components/typography/enums/fontSizeEnum'
import ColorEnum from '../../enums/colorEnum'
import Button from '../../components/buttons/button/Button';
import ButtonType from '../../components/buttons/button/logic/models/buttonType';
import api from '../../utils/api';
import {setNotification} from '../../redux/actions/ui/setNotification';
import {useDispatch} from 'react-redux';

const Settings = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const [agencyId, setAgencyId] = useState('')
    const [emailSignature, setEmailSignature] = useState('')

    const FEATURE_SETTINGS_PAGE = useFeature('settings-page').value

    useEffect( () => {
        api.get(`api/get-agency-settings`).then(res => {

            setAgencyId(res.result.agencyId)
            setEmailSignature(res.result.settings.emailSignature)
        })
    }, []);

    function onSignatureChange(e) {
        setEmailSignature(e)
    }

    async function saveEmailSignature() {
        await api.post(`api/agency/settings/save-email-signature`, {agencyId, emailSignature}).then(res => {

            dispatch(setNotification({
                id: Date.now(),
                type: 'success',
                message: 'Handtekening is succesvol opgeslagen!'
            }))

        }).catch(error => {

            dispatch(setNotification({
                id: Date.now(),
                message: 'Er is is iets fout gegaan, probeer het nog eens',
                type: 'failed'
            }))
            console.error('Error fetching data:', error)

        })
    }

    return (
        <>
            {FEATURE_SETTINGS_PAGE ?
                <PageContainer>
                    <TopNavigation/>
                    <main className={styles.container}>
                        <PageHeader title={'settingsPage.title'}/>
                        <div className={styles.contentContainer}>
                            <div className={styles.descriptionPanel}>
                                <Typography type='h6'>
                                    {`${t('settingsPage.signature.title')}`}
                                </Typography>
                                <Typography type="p">
                                    {`${t('settingsPage.signature.description')}`}
                                </Typography>
                            </div>
                            <div className={styles.settingsPanel}>
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <HighlightBlock>
                                        <Text
                                            size={FontSizeEnum.Sm}
                                            color={ColorEnum.Gray800}
                                        >
                                            {t('settingsPage.signature.info')}
                                        </Text>
                                    </HighlightBlock>
                                    <TextArea
                                        allowImage={true}
                                        value={emailSignature}
                                        onChange={onSignatureChange}
                                    />
                                </form>

                            </div>
                        </div>
                        <div className={styles.contentContainer}>
                            <div className={styles.alignRight}>
                                <Button
                                    title={'Opslaan'}
                                    buttonType={ButtonType.Primary}
                                    onClick={saveEmailSignature}
                                />
                            </div>
                        </div>
                    </main>
                </PageContainer>
                :
                <Navigate to={'dashboard/advertenties'}/>
            }
        </>
    );
};

export default Settings
