import style from './TimeInput.module.css'
import React, {useEffect, useState} from 'react'
import TimeInputModel from './model/timeInputModel'

interface TimeInputProps {
    readonly value: TimeInputModel
    readonly onChange?: (value: TimeInputModel) => void
    readonly onBlur?: (value: TimeInputModel) => void
    readonly onEnterKeyPress?: (value: TimeInputModel) => void
}

export default function TimeInput(props: TimeInputProps) {
    const [model, setModel] = useState(props.value)

    useEffect(() => {
        setModel(props.value)
    }, [props.value])

    function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setModel(prev => {
            const newModel = prev.with({ time: e.target.value })
            props.onChange?.(newModel)
            return newModel
        })
    }

    function handleBlur(e: React.FocusEvent<HTMLInputElement>): void {
        setModel(prev => {
            const newModel = prev.with({ time: e.target.value })
            props.onBlur?.(newModel)
            return newModel
        })
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
        if (e.key === 'Enter') {
            setModel(prev => {
                const newModel = prev.with({ time: (e.target as HTMLInputElement).value })
                props.onEnterKeyPress?.(newModel)
                return newModel
            })
        }
    }

    return (
        <input
            type="time"
            className={style.root}
            value={model.value.time}
            onChange={(e) => handleChange(e)}
            onBlur={(e) => handleBlur(e)}
            onKeyDown={(e) => handleKeyDown(e)}
        />
    )
}
