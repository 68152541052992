import api from '../../../../utils/api'
import DiscardedLeadJsonToModelConverter from './discardedLeadJsonToModelConverter'
import DiscardedLeadModel from '../model/discardedLeadModel'

export default class DiscardedLeadsPageService {

    public async getAgencyName(agentId: string|undefined): Promise<string> {
        if (!agentId) {
            return null;
        }

        const res = await api.get(`api/get-agency/${agentId}`)
        return res.result.name
    }

    public async getDiscardedLeads(agentId: string|undefined): Promise<DiscardedLeadModel[]> {
        if (!agentId) {
            const result = await api.get(`api/get-discarded-leads`)
            return result.result.map(item => DiscardedLeadJsonToModelConverter.convert(item))
        }

        const result = await api.get(`api/get-discarded-leads/${agentId}`)
        return result.result.map(item => DiscardedLeadJsonToModelConverter.convert(item))
    }
}
