enum ColorEnum {
    White = 'white',

    Primary100 = 'primary-100',
    Primary500 = 'primary-500',
    Primary600 = 'primary-600',
    Primary700 = 'primary-700',
    Primary50 = 'primary-50',

    Warning50 = 'warning-50',
    Warning100 = 'warning-100',
    Warning800 = 'warning-800',
    Warning900 = 'warning-900',

    Error50 = 'error-50',
    Error100 = 'error-100',
    Error600 = 'error-600',
    Error700 = 'error-700',

    Success50 = 'success-50',
    Success600 = 'success-600',
    Success700 = 'success-700',

    Gray200 = 'gray-200',
    Gray300 = 'gray-300',
    Gray400 = 'gray-400',
    Gray500 = 'gray-500',
    Gray700 = 'gray-700',
    Gray800 = 'gray-800',
    Gray900 = 'gray-900',
}

export function colorEnumAsCssVar(colorEnum: ColorEnum): string {
    return `var(--${colorEnum})`
}

export default ColorEnum
