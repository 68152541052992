import {ReactNode} from 'react';
import FontSizeEnum from '../enums/fontSizeEnum';
import ColorEnum from '../../../enums/colorEnum';
import styles from './Text.module.css';
import FontWeightEnum from '../enums/fontWeightEnum';

export interface TextProps {
    readonly children: ReactNode
    readonly size: FontSizeEnum
    readonly color: ColorEnum
    readonly weight?: FontWeightEnum
}

export default function Text(props: TextProps) {

    function getSizeCSSClassName(): string {
        return `${styles[props.size]}`
    }

    function getColorCssVar(): string {
        return `var(--${props.color})`
    }

    function getWeightCssVar(): string {
        return props.weight ? `var(--${props.weight})` : 'var(--regular-font-weight)'
    }

    return (
        <p
            className={getSizeCSSClassName()}
            style={{color: getColorCssVar(), fontWeight: getWeightCssVar()}}
        >
            {props.children}
        </p>
    )
}
