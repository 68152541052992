import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import ButtonLink from '../generics/ButtonLink'
import moment from 'moment/moment'
import {VIEWING_INVITATION_STATUS} from '../../utils/constants'
import api from '../../utils/api'

function ViewingStatusInvitationResponse({invitationStatus, listing}) {
    const {t, i18n} = useTranslation()
    const [status, setStatus] = useState(invitationStatus)
    const [formattedDateTime, setFormattedDateTime] = useState('')

    useEffect(() => {
        setFormattedDateTime(`${moment(listing.start_date_time).locale(i18n.language).format('dddd D MMMM YYYY, HH:mm')} - ${moment(listing.end_date_time).locale(i18n.language).format('HH:mm')}`)
    }, [i18n.language])

    useEffect(() => {
        const submitResponse = async () => {
            try {
                const apiEndpoint = status === VIEWING_INVITATION_STATUS.ACCEPTED
                    ? 'api/homeseeker-viewing-accepted'
                    : 'api/homeseeker-viewing-declined'
                await api.postWithoutAuth(apiEndpoint, {viewingId: listing.id})
            } catch (error) {
                console.error('Error submitting response:', error)
            }
        }
        submitResponse()
    }, [status, listing.id])

    const toggleStatus = () => {
        const newStatus = status === VIEWING_INVITATION_STATUS.ACCEPTED
            ? VIEWING_INVITATION_STATUS.DECLINED
            : VIEWING_INVITATION_STATUS.ACCEPTED

        setStatus(newStatus)
    }

    return (
        <>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t(`viewing.invitation.${status}.title`)}
            </h3>
            {status === VIEWING_INVITATION_STATUS.ACCEPTED && (
                <>
                    <p className='text-base text-gray-600 whitespace-pre-line'>
                        {t(`viewing.invitation.accepted.date.text`)}{' '}
                        <span className='font-bold'>{formattedDateTime}</span>
                        <br/><br/>
                        {t(`viewing.invitation.accepted.body`)}
                        <br/><br/>
                    </p>
                </>
            )}
            {status === VIEWING_INVITATION_STATUS.DECLINED && (
                <>
                    <p className='text-base text-gray-600 whitespace-pre-line'>
                        {t(`viewing.invitation.declined.body`)}
                        {' '}
                        {t(`viewing.invitation.declined.date.text.prefix`)}
                        {' '}
                        <span className='font-bold'>{formattedDateTime}</span>
                        {' '}
                        {t(`viewing.invitation.declined.date.text.suffix`)}
                    </p>
                </>
            )}

            <ButtonLink onClick={toggleStatus}>
                {t(`viewing.invitation.${status}.link.text`)}
            </ButtonLink>
        </>
    )
}

ViewingStatusInvitationResponse.propTypes = {
    invitationStatus: PropTypes.oneOf([
        VIEWING_INVITATION_STATUS.ACCEPTED,
        VIEWING_INVITATION_STATUS.DECLINED,
    ]).isRequired,
    listing: PropTypes.object.isRequired
}

export default ViewingStatusInvitationResponse
