import ButtonActionType from './logic/models/buttonActionType';
import styles from './Button.module.css'
import ButtonType from './logic/models/buttonType';
import ButtonSize from './logic/models/buttonSize';
import {useEffect, useRef} from 'react';

export interface ButtonProps {
    readonly size?: ButtonSize
    readonly buttonType?: ButtonType
    readonly isDisabled?: boolean
    readonly isHidden?: boolean
    readonly buttonActionType?: ButtonActionType
    readonly icon?: string
    readonly title?: string
    readonly isFullWidth?: boolean
    readonly onClick?: () => void
    readonly isUnFocused?: boolean
}

export default function Button(props: ButtonProps) {
    const buttonRef = useRef<HTMLButtonElement>(null)

    useEffect (() => {
        if (props.isUnFocused) {
            buttonRef.current.blur()
        }
    }, [props.isUnFocused]);

    function onClick() {
        props.onClick?.()
    }

    function getSizeClassName(): string {
        return props.size ? `${styles[props.size]}` : `${styles.lg}`
    }

    function getCurrentStyle() {
        if (props.buttonType === ButtonType.Primary) {
            return styles.primary
        }
        if (props.buttonType === ButtonType.SecondaryGray) {
            return styles.secondaryGray
        }
        return styles.secondary
    }

    return (
        <button
            ref={buttonRef}
            data-testid="button"
            onClick={() => onClick()}
            disabled={props.isDisabled}
            type={props.buttonActionType || ButtonActionType.Default}
            className={`
                ${styles.button}
                ${props.isFullWidth ? styles.fullWidth : ''}
                ${getCurrentStyle()}
                ${getSizeClassName()}
                ${props.isHidden ? 'hidden' : ''}
                ${props.icon ? styles.hasIcon : ''}
                ${props.title ? styles.hasTitle : ''}
                flex
            `}
        >
            {props.icon &&
                <i
                    data-testid="icon"
                    className={`${styles.icon} ${props.icon}`}
                ></i>
            }
            {props.title &&
                <span data-testid="label">
                    {props.title}
                </span>
            }
        </button>
    )
}
