import api from '../../../utils/api'
import {LISTING_ACTIONS, UI_ACTIONS} from '../../../utils/constants'

export const getAllCandidates = (token, id, params) => dispatch => {
    if(params.status === 'all'){
        params.status = null;
    }
    api.getJsonLd(`api/get-homeseeker-applications`, {...params, transactionalListingId: id})
        .then((response) => {
            dispatch({
                type: LISTING_ACTIONS.SET_CANDIDATE_COUNT_PER_SELECTED_LISTING,
                payload: response
            });
        })
        .catch((e) => {
            dispatch({type: UI_ACTIONS.STOP_LOADING});
            console.error(e);
        })
        .finally(() => dispatch({type: UI_ACTIONS.STOP_LOADING}));
}
