import React, {useEffect, useState} from 'react'
import {renderStartDate} from '../../../utils/renders'
import {useNavigate, useParams} from 'react-router-dom'
import StatusButtonGroup from '../../_archivedComponents/_statusButtonGroup'
import Button from '../../buttons/button/Button'
import ButtonType from '../../buttons/button/logic/models/buttonType'
import {Trans, useTranslation} from 'react-i18next'
import styles from './CandidateDetailsSidePanel.module.css'
import HorizontalDivider from '../../generics/horizontalDivider/HorizontalDivider'
import CandidateScreeningReportDownloadLinkModel
    from '../../molecules/candidateScreeningReportDownloadLink/models/candidateScreeningReportDownloadLinkModel'
import CandidateScreeningReportDownloadLink
    from '../../molecules/candidateScreeningReportDownloadLink/CandidateScreeningReportDownloadLink'
import CandidateScreeningDocumentsDownloadLink
    from '../../molecules/candidateScreeningDocumentsDownloadLink/CandidateScreeningDocumentsDownloadLink'
import CandidateScreeningDocumentsDownloadLinkModel
    from '../../molecules/candidateScreeningDocumentsDownloadLink/models/candidateScreeningDocumentsDownloadLinkModel'
import CandidateScreeningRatingBadge from '../../molecules/candidateScreeningRatingBadge/CandidateScreeningRatingBadge'
import InlineTextLoader from '../../molecules/inlineLoader/InlineTextLoader'
import CandidateSituationProfile from '../../candidateData/candidateProfileInfo/CandidateSituationProfile'
import CandidateSituationProfileArrangementEnum
    from '../../candidateData/candidateProfileInfo/logic/models/candidateSituationProfileArrangementEnum'
import ErrorFragment from './fragments/ErrorFragment'
import CandidateDetailsSidePanelModel from './models/candidateDetailsSidePanelModel'
import Text from '../../typography/text/Text'
import FontSizeEnum from '../../typography/enums/fontSizeEnum'
import ColorEnum from '../../../enums/colorEnum'
import TenantScreeningCard from './components/tenantCard/TenantScreeningCard'
import FontWeightEnum from '../../typography/enums/fontWeightEnum'
import ContactInformation from './components/contactInformation/ContactInformation'
import ButtonSize from '../../buttons/button/logic/models/buttonSize'
import CandidateStatusComboBadge from '../../molecules/candidateStatusComboBadge/CandidateStatusComboBadge'
import HighlightBlock from '../../../componentsNew/molecules/highlightBlock/HighlightBlock'

interface CandidateDetailsSidePanelProps {
    readonly model: CandidateDetailsSidePanelModel
    readonly onCloseButtonClick: () => void
    readonly onStatusButtonClick: any // it is a function but because of legacy reasons it's more convenient like this
    readonly onMessageSendButtonClick: () => void
    readonly onNoteCreateButtonClick: () => void
}

export default function CandidateDetailsSidePanel(props: CandidateDetailsSidePanelProps) {
    const params = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [model, setModel] = useState(props.model)

    useEffect(() => {
        setModel(props.model)
    }, [props.model])

    function showLegacyStatusButton() {
        return <StatusButtonGroup
            id={model.value.candidateModel.id}
            handleCandidateStatus={(status, idx) => props.onStatusButtonClick(status, idx, true)}
            status={model.value.candidateModel.status}
        />
    }

    async function handleDownloadReportClick(e) {
        if (model.value.isDownloadReportLoading) {
            return
        }
        e.stopPropagation()

        setModel(prev => prev.with({ isDownloadReportLoading: true }))
        await CandidateScreeningReportDownloadLinkModel.downloadReport(model.value.candidateModel)
        setModel(prev => prev.with({ isDownloadReportLoading: false }))
    }

    async function handleDownloadDocumentsClick(e) {
        if (model.value.isDownloadDocumentsLoading) {
            return
        }
        e.stopPropagation()

        setModel(prev => prev.with({ isDownloadDocumentsLoading: true }))
        await CandidateScreeningDocumentsDownloadLinkModel.downloadDocuments(model.value.candidateModel)
        setModel(prev => prev.with({ isDownloadDocumentsLoading: false }))
    }

    function parseHTMLToReact(htmlString: string) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const body = doc.body;

        const tagConfig: { [key: string]: { component: keyof JSX.IntrinsicElements; style?: React.CSSProperties } } = {
            p: { component: 'p', style: { marginBottom: '0.5rem' } },
            ul: { component: 'ul', style: { textAlign: 'left', listStyle: 'initial', marginLeft: '3rem' } },
            ol: { component: 'ol', style: { textAlign: 'left', listStyle: 'decimal', marginLeft: '3rem' } },
            li: { component: 'li', style: { marginBottom: 8 } },
            h1: { component: 'h1', style: { fontSize: '2rem', fontWeight: 'bold', marginBottom: '0.5rem' } },
            h2: { component: 'h2', style: { fontSize: '1.75rem', fontWeight: 'bold', marginBottom: '0.5rem' } },
            h3: { component: 'h3', style: { fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '0.5rem' } },
            h4: { component: 'h4', style: { fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' } },
            strong: { component: 'strong', style: { fontWeight: 'bold' } },
            em: { component: 'em', style: { fontStyle: 'italic' } },
            u: { component: 'u', style: { textDecoration: 'underline' } },
            s: { component: 's', style: { textDecoration: 'line-through' } },
            a: { component: 'a', style: { color: 'blue', textDecoration: 'underline' } },
            blockquote: { component: 'blockquote', style: { paddingLeft: '1rem', borderLeft: '4px solid #ccc', marginBottom: '0.5rem' } },
        };

        const createReactElements = (node: ChildNode): React.ReactNode => {
            if (node.nodeType === Node.TEXT_NODE) {
                return node.textContent;
            }

            if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as HTMLElement;
                const children = Array.from(element.childNodes).map(createReactElements);

                const tagName = element.tagName.toLowerCase();
                const { component: Tag, style } = tagConfig[tagName] || { component: tagName as keyof JSX.IntrinsicElements };

                const props: { [key: string]: any } = { style };
                Array.from(element.attributes).forEach(attr => {
                    props[attr.name] = attr.value;
                });

                return React.createElement(Tag, props, ...children);
            }

            return null;
        };

        return Array.from(body.childNodes).map(createReactElements);
    }

    function renderViewingsSection() {
        if (model.getViewings().length === 0) {
            return;
        }

        return (
            <div className={styles.candidateHorizonDivider}>
                <HorizontalDivider/>
                <div className={styles.candidateViewingSection}>
                    <div>
                        <Text
                            size={FontSizeEnum.Md}
                            color={ColorEnum.Gray800}
                            weight={FontWeightEnum.Semibold}
                        >
                            {t('candidate.viewing.title')}
                        </Text>
                    </div>
                    <div>
                        <ul role="list" className={styles.candidateViewingList}>
                            {model.getViewings().map(viewing => (
                                <li key={viewing.startDateTime.toISOString()}>
                                    <div className={styles.viewingItem}>
                                        <div className={styles.candidateViewingText}>
                                            <p className="overflow-hidden">
                                                {t('candidate.viewing.invitation', {
                                                    day: viewing.startDateTime.format('dddd'),
                                                    dateTimeStart: viewing.startDateTime.format('DD-MM-YYYY, HH:mm'),
                                                    dateTimeEnd: viewing.endDateTime.format('HH:mm')
                                                })}
                                            </p>
                                        </div>
                                        {viewing.statusComboBadgeModel && (
                                            <div>
                                                <CandidateStatusComboBadge model={viewing.statusComboBadgeModel}/>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    function renderScreeningSection() {
        if (model.isScreeningSectionVisible()) {
            return (
                <div className={styles.candidateHorizonDivider}>
                    <HorizontalDivider/>
                    <div className={styles.candidateScreeningSection}>
                        <div className={styles.candidateScreeningRating}>
                            <Text
                                size={FontSizeEnum.Md}
                                color={ColorEnum.Gray800}
                                weight={FontWeightEnum.Semibold}
                            >
                                {t('screening')}
                            </Text>
                            {model.value.candidateModel.hasScreeningOutcome() &&
                                <div className={styles.candidateScreeningRatingBadge}>
                                    <CandidateScreeningRatingBadge
                                        model={model.getCandidateScreeningRatingBadgeModel()}
                                    />
                                </div>
                            }
                        </div>

                        {model.value.candidateModel.hasScreeningOutcome() &&
                            <div className={styles.candidateScreeningRatingText}>
                                <Trans
                                    i18nKey={model.getCandidateScreeningRatingBadgeModel().getTooltipText()}
                                    components={{
                                        ul: <ul className={styles.candidateScreeningRatingList}/>,
                                        li: <li/>
                                    }}
                                />
                            </div>
                        }

                        {model.value.candidateModel.hasScreeningOutcome() ?
                            <>
                                <div className={styles.candidateScreeningButtonOptionsContainer}>
                                    {model.value.isDownloadReportLoading ?
                                        <div className={styles.inlineTextLoader}>
                                            <InlineTextLoader/>
                                        </div>
                                        :
                                        <div
                                            className={styles.candidateScreeningReportDownloadLink}
                                            onClick={handleDownloadReportClick}
                                        >
                                            <CandidateScreeningReportDownloadLink candidateModel={model.value.candidateModel}/>
                                        </div>
                                    }

                                    <li className={styles.candidateScreeningContainerBullet}></li>

                                    {model.value.isDownloadDocumentsLoading ?
                                        <div className={styles.inlineTextLoader}>
                                            <InlineTextLoader/>
                                        </div>
                                        :
                                        <div onClick={handleDownloadDocumentsClick}>
                                            <CandidateScreeningDocumentsDownloadLink
                                                candidateModel={model.value.candidateModel}
                                            />
                                        </div>
                                    }
                                </div>
                            </> :
                            <div className={styles.candidateTenantContainer}>
                                <Text weight={FontWeightEnum.Regular} size={FontSizeEnum.Sm} color={ColorEnum.Gray400}>{t('candidate.status.screening.report_not_available')}</Text>
                            </div>
                        }

                        {model.value.candidateModel.hasCoTenants &&
                            <div className={styles.candidateTenantContainer}>
                                <div className={styles.candidateTenantDetails}>
                                    <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm} color={ColorEnum.Gray500}>{t('candidate.screening.main_tenant')}</Text>
                                </div>
                                <TenantScreeningCard
                                    fullName={model.value.candidateModel.fullName}
                                    screeningStatus={model.value.candidateModel.screeningRequestStatus}
                                    hideHomeseekerDetails={true}
                                />
                                <div className={styles.candidateTenantContainer}>
                                    <div className={styles.candidateTenantDetails}>
                                        <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm} color={ColorEnum.Gray500}>{t('candidate.screening.co_tenants')}</Text>
                                    </div>
                                    {model.getCoTenantScreeningRequests().map(c => {
                                        return (
                                            <div key={c.value.id} className={styles.tenantScreeningCard}>
                                                <TenantScreeningCard
                                                    fullName={c.value.fullName}
                                                    screeningStatus={c.value.screeningRequestStatus}
                                                    phone={c.value.phoneNumber}
                                                    email={c.value.email}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }
    }

    function renderDetails() {
        const candidate = model.value.candidateModel;
        return model.value.candidateModel ? <div className={styles.sidePanelHeight}>
            <div className={styles.sidePanelPadding}>
                <div className={styles.candidateContainer}>
                    <div className="flex items-start justify-between">
                        <div className={styles.candidateHeader}>
                            <h3 className={styles.candidateName}>
                                {t(candidate.getSalutation())} {candidate.firstName}
                            </h3>
                            <div className={styles.rightTop}>
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-600
                                        hover:text-gray-500 focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300
                                        focus:border-[1px]"
                                    onClick={() => props.onCloseButtonClick()}
                                >
                                    <i className="ri-close-line text-3xl"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div>
                            <div className={styles.contactInfoWrapper}>
                                <div className="sm:flex-1">
                                    <ContactInformation
                                        birthday={model.value.candidateModel.formatDateAndAge()}
                                        email={model.value.candidateModel.email}
                                        phone={model.value.candidateModel.phone}
                                        employment={model.value.candidateModel.employment}
                                    />
                                    <div className={styles.LegacyStatusButton}>
                                        {showLegacyStatusButton()}
                                    </div>
                                    <div>
                                        <HighlightBlock
                                            type='info'
                                        >
                                            <p className={styles.informationRejectionMessage}>{t('candidate.popover.rejection.message')}</p>
                                        </HighlightBlock>
                                    </div>
                                    <HorizontalDivider/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.candidateExtraInfo}>
                        <Text
                            size={FontSizeEnum.Md}
                            color={ColorEnum.Gray800}
                            weight={FontWeightEnum.Semibold}
                        >
                            {t('candidate.popover.title.candidateData')}
                        </Text>
                        <div className={styles.badgeText}>
                            <CandidateSituationProfile
                                model={model.getCandidateSituationProfileModel()}
                                arrangement={CandidateSituationProfileArrangementEnum.ThreeColumns}
                            />
                        </div>
                    </div>
                    {renderViewingsSection()}
                    {renderScreeningSection()}
                    <div className={styles.candidateHorizonDivider}>
                        <HorizontalDivider/>
                    </div>
                    <section aria-labelledby="notes-title">
                        <div className="bg-white sm:rounded-lg sm:overflow-hidden">
                            <div className={styles.candidateMessageTitle}>
                                <Text
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray800}
                                    weight={FontWeightEnum.Semibold}
                                >
                                    {t('candidate.popover.title.message')}
                                </Text>

                                <Button
                                    size={ButtonSize.Md}
                                    title={t('candidate.action.initiateMessage')}
                                    buttonType={ButtonType.Primary}
                                    icon='ri-mail-line'
                                    onClick={() => props.onMessageSendButtonClick()}
                                />
                            </div>
                            <div className={styles.candidateMessageContainer}>
                                <ul role="list">
                                    {model.value.candidateModel?.messages?.map((message, index) => (
                                        <li key={index} id="selectedCandidate-message">
                                            <div className={styles.candidateMessageField}>
                                                <div className="flex-shrink-0">
                                                    <img
                                                        className="h-10 w-10 rounded-full"
                                                        src={`https://via.placeholder.com/420/cecece/FFFFFF?text=${model.value.candidateModel.firstName.slice(0, 1)}`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="whitespace-pre-line overflow-hidden break-words break-normal">
                                                    <div className="text-base ">
                                                        <p className="font-bold text-gray-800">
                                                            {message.subject}
                                                        </p>
                                                    </div>
                                                    <div className={styles.candidateMessageText}>
                                                        {parseHTMLToReact(message.body)}
                                                    </div>
                                                    <div className={styles.candidateMessageStartDate}>
                                                        <span className="text-gray-400 font-medium">
                                                            {renderStartDate(message.createdAt)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </section>
                    <div className={styles.candidateHorizonDivider}>
                        <HorizontalDivider/>
                    </div>
                    <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                        <div className={styles.candidateNotesContainer}>
                            <div className="bg-white sm:rounded-lg sm:overflow-hidden">
                                <div className={styles.candidateNotesTitle}>
                                    <Text
                                        size={FontSizeEnum.Md}
                                        color={ColorEnum.Gray800}
                                        weight={FontWeightEnum.Semibold}
                                    >
                                        {t('candidate.notes.title')}
                                    </Text>

                                    <Button
                                        size={ButtonSize.Md}
                                        title={t('candidate.notes.createNote')}
                                        buttonType={ButtonType.SecondaryGray}
                                        icon='ri-pencil-line'
                                        onClick={() => props.onNoteCreateButtonClick()}
                                    />
                                </div>
                                <div className="">
                                    <ul role="list">
                                        {model.value.candidateModel.notes?.length < 1 && <li>
                                            <div className="flex space-x-3">
                                                <div>
                                                    <div className={styles.candidatePlaceholderNotes}>
                                                        <p>{t('candidate.popover.title.placeholder.notesArea')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>}
                                        {model.value.candidateModel.notes?.map((note) => (
                                            <li key={note.id}>
                                                <div className={styles.candidateNotestMessageContainer}>
                                                    <div className="whitespace-pre-line overflow-hidden break-words break-normal">
                                                        <div className="text-base">
                                                            <p className={styles.candidateNotesText}>
                                                                {note.note}
                                                            </p>
                                                            <span className={styles.candidateNotesStartDate}>
                                                                {renderStartDate(note.createdAt)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div> : <ErrorFragment onCloseButtonClick={props.onCloseButtonClick} />
    }

    return model.value.isCandidateLoading ? <></> : renderDetails()
}
