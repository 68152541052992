import React, {useState} from 'react';
import PropTypes from 'prop-types';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function BadgesList (props) {
    const {badges, maxBeforeDropdown, resourcePlural, resourceSingular} = props;
    const [isOpened, setIsOpened] = useState(false);

    return (
        <div className='col-span-6 flex flex-col items-start space-y-2'>
            <label className="text-gray-500 sm:text-md">
                {
                    badges.length > 1 ?
                        `${badges.length} ${resourcePlural} geselecteerd` :
                        `${badges.length} ${resourceSingular} geselecteerd`
                }
            </label>
            <span className={classNames(isOpened ? 'flex flex-wrap' : 'flex-row flex w-full truncate overflow-hidden', ' text-gray-900 h-auto')}>
                {(isOpened ? badges : badges.slice(0, maxBeforeDropdown)).map((title) =>
                    <span
                        key={badges.indexOf(title)}
                        className="px-2 py-0.5 mr-2 my-1 border bg-gray-50 border-gray-300 rounded-md">{title}</span>
                )}
                {
                    (!isOpened && badges.length > maxBeforeDropdown) && <span
                        className="px-2 py-0.5 mr-2 my-1 border bg-gray-50 border-gray-300 rounded-md">+{badges.length-maxBeforeDropdown}</span>
                }
            </span>
            {badges.length > maxBeforeDropdown &&
                <div className="relative flex items-start">
                    <button
                        className="font-medium text-primary text-md underline underline-offset-4"
                        onClick={()=> setIsOpened(!isOpened)}>
                        {isOpened ? 'Minder zien' : 'Meer zien'}
                    </button>

                </div>
            }
        </div>
    );
}


BadgesList.propTypes = {
    badges: PropTypes.array,
    maxBeforeDropdown: PropTypes.number,
    resourcePlural: PropTypes.string,
    resourceSingular: PropTypes.string,
};

export default BadgesList;
