import api from '../utils/api'
import InviteViewingModalModel from '../components/modals/viewing/model/inviteViewingModalModel'

export default class ListingDetailsService {

    public async sendInvitiation(homeseekerApplicationIds: string[], transactionalListingId: string, invitationModel: InviteViewingModalModel): Promise<void> {
        const payload = {
            homeseekerApplicationIds,
            transactionalListingId,
            subject: invitationModel.value.messageSubject,
            message: invitationModel.value.messageBody,
            startDateTime: invitationModel.value.invitationStartDateTime.clone().format('YYYY-MM-DD HH:mm:ss'),
            endDateTime: invitationModel.value.invitationEndDateTime.clone().format('YYYY-MM-DD HH:mm:ss'),
            includeDateAndTimeInEmail: invitationModel.value.includeDateAndTimeInEmail,
        }

        await api.post('api/invite-homeseeker-to-viewing', payload)
    }
}
