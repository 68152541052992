import moment from 'moment'
import 'moment/locale/nl';
import ViewingModel from '../models/candidate/viewingModel'
import CandidatePlace from '../enums/candidatePlace'
import CandidateViewingStatus from '../enums/candidateViewingStatus'
import CandidateStatusComboBadgeModel
    from '../components/molecules/candidateStatusComboBadge/models/candidateStatusComboBadgeModel'
import {stringToEnum} from '../utils/enumType'

interface ViewingJSON {
    readonly startDateTime: string,
    readonly endDateTime: string
    readonly status: string;
}

export const getCandidatePlaceFromViewingStatus = (status: string): CandidatePlace => {
    if (status === CandidateViewingStatus.Pending) {
        return CandidatePlace.InvitedToViewing;
    }
    if (status === CandidateViewingStatus.Accepted) {
        return CandidatePlace.AcceptedInvitationToViewing;
    }
    if (status === CandidateViewingStatus.Declined) {
        return CandidatePlace.DeclinedInvitationToViewing;
    }

    return null;
}

export default class ViewingModelToJsonConverter {
    public static convert(obj: ViewingJSON) {
        return new ViewingModel(
            moment(obj.startDateTime),
            moment(obj.endDateTime),
            stringToEnum(obj.status, CandidateViewingStatus),
            CandidateStatusComboBadgeModel.createAccordingToStatusCombo(getCandidatePlaceFromViewingStatus(obj.status)),
        )
    }
}
