import React, {ReactNode} from 'react'
import styles from './PageHeader.module.css'
import Typography from '../../atoms/typography/Typography'
import {t} from 'i18next'

interface props {
    readonly title: string
}

const PageHeader = ({title}: props) => {
    return (
        <header className={styles.container}>
            <Typography type='h1'>
                {`${t(title)}`}
            </Typography>
        </header>
    )
}

export default PageHeader
