import ImmutableModel from '../../../../models/immutableModel/immutableModel'
import moment, {Moment} from 'moment'
import CandidateModel from '../../../../models/candidate/candidateModel'
import TimeInputModel from '../../../atoms/timeInput/model/timeInputModel'

interface InviteViewingModalModelProps {
    readonly messageSubject: string
    readonly messageBody: string
    readonly messageRecipients: ReadonlyArray<CandidateModel>
    readonly invitationDate: Moment
    readonly invitationStartDateTime: Moment
    readonly invitationEndDateTime: Moment
    readonly includeDateAndTimeInEmail: boolean
}


export default class InviteViewingModalModel extends ImmutableModel<InviteViewingModalModel, InviteViewingModalModelProps> {

    public static create(initialMessageSubject: string, messageRecipients: CandidateModel[]): InviteViewingModalModel {
        const initialDate = moment().seconds(0)
        const initialStartDateTime = initialDate.clone().add(1, 'hour')
        const initialEndDateTime = initialDate.clone().add(2, 'hour')

        return new InviteViewingModalModel({
            messageSubject: initialMessageSubject,
            messageBody: '',
            messageRecipients: messageRecipients,
            invitationDate: initialDate,
            invitationStartDateTime: initialStartDateTime,
            invitationEndDateTime: initialEndDateTime,
            includeDateAndTimeInEmail: true
        })
    }

    public getMinimumSelectableInvitationDate(): Moment {
        return moment().seconds(0)
    }

    public updateInvitationDate(year: number, month: number, dateDay: number): InviteViewingModalModel {
        const date = moment().year(year).month(month).date(dateDay).hours(0).minutes(0).seconds(0)

        const invitationStartTime = date.clone()
            .hour(this.value.invitationStartDateTime.hour())
            .minute(this.value.invitationStartDateTime.minute())

        const invitationEndTime = date.clone()
            .hour(this.value.invitationEndDateTime.hour())
            .minute(this.value.invitationEndDateTime.minute())

        return this.with({
            invitationDate: date,
            invitationStartDateTime: invitationStartTime,
            invitationEndDateTime: invitationEndTime
        })
    }

    public updateInvitationStartDateTime(val: TimeInputModel): InviteViewingModalModel {
        const newStartDateTime = this.value.invitationStartDateTime
            .clone()
            .hour(val.getHour())
            .minute(val.getMinute())

        return this.with({
            invitationStartDateTime: newStartDateTime
        })
    }

    public updateInvitationStartDateTimeMaintainingTimeDiff(val: TimeInputModel): InviteViewingModalModel {
        const newStartDateTime = this.value.invitationStartDateTime.clone().hour(val.getHour()).minute(val.getMinute())
        const currentStartDateTime = this.value.invitationStartDateTime.clone()
        const currentEndDateTime = this.value.invitationEndDateTime.clone()

        const difference = moment.duration(newStartDateTime.diff(currentStartDateTime))
        const newEndDateTime = moment(currentEndDateTime).add(difference)

        return this.with({
            invitationStartDateTime: this.value.invitationStartDateTime
                .clone()
                .hour(val.getHour())
                .minute(val.getMinute()),
            invitationEndDateTime: newEndDateTime.clone()
        })
    }

    public updateInvitationEndDateTime(val: TimeInputModel): InviteViewingModalModel {
        const newEndDateTime = this.value.invitationEndDateTime
            .clone()
            .hour(val.getHour())
            .minute(val.getMinute())

        return this.with({
            invitationEndDateTime: newEndDateTime
        })
    }

    public getRecipientNames(): string[] {
        return this.value.messageRecipients.map(c => c.firstName)
    }

    public getInvitationStartTimeInputModel(): TimeInputModel {
        return new TimeInputModel({time: this.value.invitationStartDateTime.format('HH:mm')})
    }

    public getInvitationEndTimeInputModel(): TimeInputModel {
        return new TimeInputModel({time: this.value.invitationEndDateTime.format('HH:mm')})
    }

    public isMessageSubjectInvalid(): boolean {
        return this.value.messageSubject.length === 0
    }

    public isMessageBodyInvalid(): boolean {
        return this.value.messageBody.length === 0 || this.value.messageBody === '<p><br></p>'
    }

    public isInvitationStartDateTimeInThePast(): boolean {
        return this.value.invitationStartDateTime.isBefore(moment())
    }

    public isInvitationStartDateTimeTheSameAsEndDateTime(): boolean {
        return this.value.invitationEndDateTime.isSame(this.value.invitationStartDateTime)
    }

    public isInvitationStartDateTimeTooCloseToCurrentTime(): boolean {
        return this.value.invitationStartDateTime.isSameOrBefore(moment().add(14, 'minute').add(59, 'second')) &&
            this.value.invitationStartDateTime.isSameOrAfter(moment())
    }

    public isInvitationEndDateTimeBeforeStartDateTime(): boolean {
        return this.value.invitationEndDateTime.isBefore(this.value.invitationStartDateTime)
    }

    public isInvitationDateInvalid(): boolean {
        const m = this.value.invitationDate

        if (isNaN(m.date()) || isNaN(m.month()) || isNaN(m.year())) {
            return false
        }

        return true
    }

    public isInvitationStartDateTimeInvalid(): boolean {
        const m = this.value.invitationStartDateTime

        if (isNaN(m.hour()) || isNaN(m.minute())) {
            return false
        }

        return true
    }

    public isInvitationEndDateTimeInvalid(): boolean {
        const m = this.value.invitationEndDateTime

        if (isNaN(m.hour()) || isNaN(m.minute())) {
            return false
        }

        return true
    }

    public isSubmitButtonEnabled(): boolean {
        return this.value.messageRecipients.length > 0 &&
            this.value.invitationDate.isSameOrAfter(moment(), 'day') &&
            !this.isMessageSubjectInvalid() &&
            !this.isMessageBodyInvalid() &&
            !this.isInvitationStartDateTimeInThePast() &&
            !this.isInvitationStartDateTimeTheSameAsEndDateTime() &&
            !this.isInvitationStartDateTimeTooCloseToCurrentTime() &&
            !this.isInvitationEndDateTimeBeforeStartDateTime()
    }
}
