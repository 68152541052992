import {Moment} from 'moment'
import CandidateStatusComboBadgeModel from '../../components/molecules/candidateStatusComboBadge/models/candidateStatusComboBadgeModel'
import CandidateViewingStatus from '../../enums/candidateViewingStatus'

export default class ViewingModel {
    constructor(
        public readonly startDateTime: Moment,
        public readonly endDateTime: Moment,
        public readonly status: CandidateViewingStatus,
        public readonly statusComboBadgeModel?: CandidateStatusComboBadgeModel,
    ) {}
}
